<template>
  <div></div>
</template>

<script>
import {defineComponent, getCurrentInstance, onMounted} from 'vue'
import {message} from 'ant-design-vue'
import {request} from '@/utils/request'
import {useStore} from 'vuex';
// 由于token具有时限，因此需要按时刷新
export default defineComponent({
    setup() {
    const store = useStore();
    // 获取全局变量的方式
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const refreshToken = () => {
      request({
        // 获取全局变量的方式
            url: baseUrl + "/jwt-refresh/",
            method: "post",
            withCredentials: true,
            data: {token: store.state.user_login_auth.user["token"]},
          }).then((response) => {
            if (response.status === 200) {
              const payloads = {
                username: store.state.user_login_auth.user["username"],
                authenticated: true,
                token: response.data["token"],
              };
              store.commit({
                type: "user_login_auth/saveData",
                payload: payloads,
              });
              message.success('成功更新授权！')
            } 
          })
          .catch((error) => {
              const payloads = {
                authenticated: false,
              };
              store.commit({
                type: "user_login_auth/saveData",
                payload: payloads,
              });
            message.error(error + '刷新权限失败请重新登录！')  
          })
          ;
    }
    setInterval(() => {
      if (store.state.user_login_auth.user["authenticated"] === true) {
        refreshToken()
      }
    }, 600000)
    onMounted(() => {
      if (store.state.user_login_auth.user["authenticated"] === true) {
        refreshToken()
      }
    })
    }
})
</script>

<style>

</style>