<template>
<AutoRefreshToken />
  <a-popover v-model:visible="visible" :title="authInfo.authTitle" trigger="click">
    <template #content>
      <a-switch v-model:checked="checked" @change="alterServerChange"/>
      <p>
      <a-typography-text mark>备用服务器开关</a-typography-text>
      </p>
      <p>
      <a-typography-text type='danger'>【通常情况下请不要打开】</a-typography-text>
      </p>
      <p>
      <a-typography-text type='warning'>如果更换服务器需要重新登录</a-typography-text>
      </p>
      <a-divider style="height: 2px; background-color: #7cb305" />
      <CommonAuth
            v-if="$store.state.user_login_auth.user['authenticated'] === false"
      />

      <LogOut
            v-if="$store.state.user_login_auth.user['authenticated'] === true"
      />
      <a-divider style="height: 2px; background-color: #7cb305" />
      <a @click="hide">关闭</a>
    </template>
    <a-button type="primary">{{ authInfo.buttonTitle }}</a-button>
  </a-popover>
</template>
<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import {useStore} from 'vuex';
import AutoRefreshToken from './AutoRefreshToken';
import CommonAuth from "@/components/auth/CommonAuth";
import LogOut from "@/components/auth/LogOut";
let moment = require("moment");
export default defineComponent({
  setup() {
    const visible = ref(false);
    const hide = () => {
      visible.value = false;
    };
    // 该版本需要使用store申明方式
    const store = useStore();
    // 获取全局变量的方式
    watch (() => store.state.user_login_auth.user, function() {
      visible.value = false;
    });
    // 根据是否获取登录授权，决定显示的信息
    const authInfo = computed(() => {
      return store.state.user_login_auth.user['authenticated'] === true
      ? {authTitle:store.state.user_login_auth.user["username"] +
          "，您好，今天是：" +
          moment().format("YYYY年MM月DD日"),
          buttonTitle: "您好：" + store.state.user_login_auth.user["username"]
          }
      : {authTitle: '请登录！',
        buttonTitle: "点击登录"
      }
    });
    const checked = ref(false)
    // 读取是否使用备用服务器，如果使用备用服务器，会使用main.js中备用服务器的 base url
    onMounted(() => {
      if (checked.value !== store.state.alternate_server.server['alternate']) {
        checked.value = store.state.alternate_server.server['alternate']
      }
    })
    watch (() => store.state.alternate_server.server, function() {
      checked.value = store.state.alternate_server.server['alternate']
    });
    const alterServerChange = () => {
      const payloadsAlternate = {
                alternate: checked
              };
              store.commit({
                type: "alternate_server/saveData",
                payload: payloadsAlternate,
              });
      const payloads = {
                authenticated: false,
                token: ''
              };
              store.commit({
                type: "user_login_auth/saveData",
                payload: payloads,
              });
    }
    return {
      visible,
      hide,
      authInfo,
      checked,
      alterServerChange,
    };
  },
  components: {
    CommonAuth,
    LogOut,
    AutoRefreshToken
  }
});
</script>