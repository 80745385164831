<template>
  <a-form layout="horizontal">
    <a-form-item>
      <a-input v-model:value="formState.username" placeholder="用户名" />
    </a-form-item>
    <a-form-item>
      <a-input v-model:value="formState.password" type="password" placeholder="密码" />
    </a-form-item>
    <a-form-item >
      <a-button type="primary" @click="onSubmit">登录</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, reactive, toRaw, getCurrentInstance } from 'vue';
import {message} from 'ant-design-vue';
import {useStore} from 'vuex';
import {notification} from 'ant-design-vue';
import {request} from '@/utils/request'
// 登录并将登录状态写入store中（vuex）中
export default defineComponent({
  setup() {
    const formState = reactive({
      username: '',
      password: '',
    });
    const store = useStore();
    // 获取全局变量的方式
    const properties = getCurrentInstance()
    const baseUrl = store.state.alternate_server.server['alternate'] ? properties.appContext.config.globalProperties.$alternateServerUrl : properties.appContext.config.globalProperties.$mainServerUrl
    const onSubmit = () => {
      request({
        // 获取全局变量的方式
            url: baseUrl + "/jwt-token/",
            method: "post",
            withCredentials: true,
            data: toRaw(formState),
          }).then((response) => {
            if (response.status == 200) {
              const payloads = {
                username: formState.username,
                authenticated: true,
                token: response.data["token"],
              };
              store.commit({
                type: "user_login_auth/saveData",
                payload: payloads,
              });
              if (payloads['authenticated']) {
                notification.success({
                // eslint-disable-next-line no-unused-vars
                message: "登陆成功！",
                description: "欢迎您访问，尊敬的：" + payloads["username"],
                duration: 1.5,
              });
              } else {
                notification.error({
                // eslint-disable-next-line no-unused-vars
                message: "由于未知原因登陆失败！",
                description: "请联系管理员或点我重新尝试。",
                duration: 3,
                onClick: () => location.reload(),
              });
              }
            }
          }).catch((error) => {
            message.error(error+' 登录失败！')
          });
    };
    return {
      formState,
      onSubmit,
      baseUrl
    };
  },
});
</script>