<template>
  <a-button type="danger" @click="LogOut"> 注销 </a-button>
</template>

<script>
// 通过清理store，达到本地登出的目的
export default {
  methods: {
    LogOut() {
      window.clearVuexAlong();
      this.$router.go(0);
    },
  },
};
</script>

<style>
</style>